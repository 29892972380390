exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-features-booking-assistance-tsx": () => import("./../../../src/pages/features/booking-assistance.tsx" /* webpackChunkName: "component---src-pages-features-booking-assistance-tsx" */),
  "component---src-pages-features-booking-method-tsx": () => import("./../../../src/pages/features/booking-method.tsx" /* webpackChunkName: "component---src-pages-features-booking-method-tsx" */),
  "component---src-pages-features-customer-management-tsx": () => import("./../../../src/pages/features/customer-management.tsx" /* webpackChunkName: "component---src-pages-features-customer-management-tsx" */),
  "component---src-pages-features-front-desk-assistance-tsx": () => import("./../../../src/pages/features/front-desk-assistance.tsx" /* webpackChunkName: "component---src-pages-features-front-desk-assistance-tsx" */),
  "component---src-pages-features-index-tsx": () => import("./../../../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-features-invoicing-and-payments-tsx": () => import("./../../../src/pages/features/invoicing-and-payments.tsx" /* webpackChunkName: "component---src-pages-features-invoicing-and-payments-tsx" */),
  "component---src-pages-features-managing-reporting-tsx": () => import("./../../../src/pages/features/managing-reporting.tsx" /* webpackChunkName: "component---src-pages-features-managing-reporting-tsx" */),
  "component---src-pages-features-scheduling-solution-tsx": () => import("./../../../src/pages/features/scheduling-solution.tsx" /* webpackChunkName: "component---src-pages-features-scheduling-solution-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-cleaning-tsx": () => import("./../../../src/pages/industry/cleaning.tsx" /* webpackChunkName: "component---src-pages-industry-cleaning-tsx" */),
  "component---src-pages-industry-clinic-tsx": () => import("./../../../src/pages/industry/clinic.tsx" /* webpackChunkName: "component---src-pages-industry-clinic-tsx" */),
  "component---src-pages-industry-index-tsx": () => import("./../../../src/pages/industry/index.tsx" /* webpackChunkName: "component---src-pages-industry-index-tsx" */),
  "component---src-pages-industry-restaurant-tsx": () => import("./../../../src/pages/industry/restaurant.tsx" /* webpackChunkName: "component---src-pages-industry-restaurant-tsx" */),
  "component---src-pages-industry-salon-tsx": () => import("./../../../src/pages/industry/salon.tsx" /* webpackChunkName: "component---src-pages-industry-salon-tsx" */),
  "component---src-pages-industry-service-workshop-tsx": () => import("./../../../src/pages/industry/service-workshop.tsx" /* webpackChunkName: "component---src-pages-industry-service-workshop-tsx" */),
  "component---src-pages-industry-training-centre-tsx": () => import("./../../../src/pages/industry/training-centre.tsx" /* webpackChunkName: "component---src-pages-industry-training-centre-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-template-markdown-template-tsx": () => import("./../../../src/template/markdown-template.tsx" /* webpackChunkName: "component---src-template-markdown-template-tsx" */)
}

